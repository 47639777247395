// routes
import {paths} from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
// ROOT PATH AFTER LOGIN SUCCESSFUL
/* export const PATH_AFTER_LOGIN = paths.app.root; // as '/app/dashboard' */

// Route par défaut vers la page de login
export const DEFAULT_PATH = paths.auth.login;
