import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// locales
import {useLocales} from 'src/locales';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export function useNavData() {
  const {t} = useLocales();

  const data = useMemo(
      () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
        {
          subheader: t('vue d\'ensemble'),
          items: [
            {
              title: t('dashboard'),
              path: paths.admin.root,
              icon: <Iconify icon="clarity:dashboard-line" />,
            },
            {
              title: t('établissements'),
              path: paths.admin.companies,
              icon: <Iconify icon="clarity:store-solid" />,
            },
            {
              title: t('factures'),
              path: paths.admin.invoices,
              icon: <Iconify icon="clarity:file-group-line" />,
            },
            {
              title: t('utilisateurs'),
              path: paths.admin.users,
              icon: <Iconify icon="clarity:administrator-line" />,
            },
            {
              title: t('catalogue'),
              path: paths.admin.giftVoucherTypes,
              icon: <Iconify icon="clarity:directory-line" />,
            },
            {
              title: t('ventes'),
              path: paths.admin.giftVoucherList,
              icon: <Iconify icon="clarity:data-cluster-line" />,
            },
          ],
        },
      ],
      [t],
  );

  return data;
}
