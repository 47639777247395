import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from 'src/hooks';
import {checkAccess} from 'src/utils/rolesConfig';
import {paths} from 'src/routes/paths';

export const useRedirectAfterAuth = () => {
  const navigate = useNavigate();
  const {isAuthenticated, userRole, loading, isOnboardingCompleted} = useAuthContext();

  useEffect(() => {
    const handleRedirect = () => {
      // Si toujours en chargement ou pas authentifié, on ne fait rien
      if (loading || !isAuthenticated || !userRole) {
        return;
      }

      // Vérifie d'abord s'il y a un chemin pré-authentification à restaurer
      const preAuthPath = localStorage.getItem('preAuthPath');
      if (preAuthPath) {
        localStorage.removeItem('preAuthPath');

        // Vérifie si l'utilisateur a accès au chemin pré-auth
        const isAdminPath = preAuthPath.startsWith(paths.admin.root);
        const isAppPath = preAuthPath.startsWith(paths.app.root);

        if ((isAdminPath && checkAccess(userRole, paths.admin.root)) ||
            (isAppPath && checkAccess(userRole, paths.app.root))) {
          navigate(preAuthPath);
          return;
        }
      }

      // Redirection basée sur le rôle
      if (userRole === 'AppAdmin') {
        // Les admins sont toujours redirigés vers l'interface admin
        navigate(paths.admin.root, {replace: true});
      } else if (checkAccess(userRole, paths.app.root)) {
        // Pour les utilisateurs de l'application
        if (!isOnboardingCompleted) {
          navigate(paths.app.onboarding, {replace: true});
        } else {
          navigate(paths.app.dashboard, {replace: true});
        }
      } else {
        // Fallback pour les rôles non reconnus
        navigate('/', {replace: true});
      }
    };

    handleRedirect();
  }, [isAuthenticated, userRole, loading, isOnboardingCompleted, navigate]);
};
