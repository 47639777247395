import {Navigate, useRoutes} from 'react-router-dom';
import {DEFAULT_PATH} from 'src/ConfigGlobal';
import {LoadingScreen} from 'src/components/loading-screen';
import {useAuthContext} from 'src/hooks';
import {paths} from 'src/routes/paths';
import {mainRoutes} from './Main';
import {authRoutes} from './Auth';
import {appRoutes} from './App';
import {adminRoutes} from './Admin';
import {publicRoutes} from './Public';

const RootRedirect = () => {
  const {isAuthenticated, userRole, loading} = useAuthContext();

  // Afficher un loader pendant la vérification de l'authentification
  if (loading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={DEFAULT_PATH} replace />;
  }

  if (userRole === 'AppAdmin') {
    return <Navigate to={paths.admin.root} replace />;
  }

  return <Navigate to={paths.app.root} replace />;
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <RootRedirect />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard User routes
    ...appRoutes,

    // Dashboard Admin routes
    ...adminRoutes,

    // Main routes
    ...mainRoutes,

    // Routes publiques des établissements
    ...publicRoutes,

    // No match 404
    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}
