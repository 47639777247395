import {format, getTime, formatDistanceToNow} from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// ----------------------------------------------------------------------

/**
 * Formate une date selon un format spécifié ou par défaut en 'dd MMM yyyy'.
 * @param {string|Date} date - La date à formater.
 * @param {string} [newFormat] - Le nouveau format souhaité pour la date.
 * @return {string} La date formatée.
 */
export function fDate(date, newFormat) {
  const fm = newFormat || 'dd/MM/yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fSimpleDate(date) {
  if (!date) return '';

  // On utilise directement les getUTC methods pour extraire jour/mois/année
  const d = new Date(date);
  const day = String(d.getUTCDate()).padStart(2, '0');
  const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // +1 car les mois commencent à 0
  const year = d.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

/**
 * Formate une date et heure selon un format spécifié ou par défaut en 'dd MMM yyyy à HH:mm'.
 * Utilisé pour afficher la date et l'heure en format 24 heures.
 * @param {string|Date} date - La date et l'heure à formater.
 * @param {string} [newFormat] - Le nouveau format souhaité pour la date et l'heure.
 * @return {string} La date et l'heure formatées.
 */
export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd/MM/yyyy à HH:mm';

  return date ? format(new Date(date), fm) : '';
}

dayjs.extend(utc);
/**
 * Formate une date UTC ou renvoie 'Indéterminée' si la date est '9999-12-31'.
 * @param {string|Date} date - La date à formater.
 * @return {string} La date formatée en UTC ou 'Indéterminée'.
 */
export function fDateValidity(date) {
  if (!date) return '';

  // Utilise dayjs.utc() pour travailler avec la date en UTC
  const utcDate = dayjs.utc(date);
  const formattedDate = utcDate.format('DD/MM/YYYY');

  return formattedDate === '31/12/9999' ? 'Indéterminée' : formattedDate;
}

/**
 * Récupère le timestamp d'une date.
 * @param {string|Date} date - La date pour obtenir le timestamp.
 * @return {number} Le timestamp de la date.
 */
export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

/**
 * Formate une date pour afficher le temps écoulé jusqu'à maintenant avec un suffixe.
 * Par exemple : 'il y a 3 jours'.
 * @param {string|Date} date - La date à partir de laquelle calculer le temps écoulé.
 * @return {string} Le temps écoulé depuis la date donnée jusqu'à maintenant.
 */
export function fToNow(date) {
  return date ?
    formatDistanceToNow(new Date(date), {
      addSuffix: true,
    }) :
    '';
}
