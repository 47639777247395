const ROOTS = {
  AUTH: '/auth',
  APP: '/dashboard',
  ADMIN: '/admin',
  COMPANY: '/explore',
};

export const paths = {
  siteURL: 'https://brydge.app/',

  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    verifyEmail: `${ROOTS.AUTH}/verify-email/:token`,
    requestVerification: `${ROOTS.AUTH}/request-verification`,
    requestResetPassword: `${ROOTS.AUTH}/request-reset-password`,
    resetPassword: `${ROOTS.AUTH}/reset-password/:token`,
  },

  // ONBOARDING
  onboarding: {
    root: `${ROOTS.APP}/onboarding`,
  },

  // DASHBOARD USER
  app: {
    root: ROOTS.APP,
    onboarding: `${ROOTS.APP}/onboarding`,
    dashboard: `${ROOTS.APP}`,
    reporting: `${ROOTS.APP}/reporting`,
    userAccount: `${ROOTS.APP}/user-account`,
    company: `${ROOTS.APP}/company`,
    users: `${ROOTS.APP}/users`,
    invoices: `${ROOTS.APP}/invoices`,
    giftVoucher: {
      root: `${ROOTS.APP}/gift-voucher`,
      types: {
        list: `${ROOTS.APP}/gift-voucher/types`,
        create: `${ROOTS.APP}/gift-voucher/types/create`,
        createValue: `${ROOTS.APP}/gift-voucher/types/create/value`,
        createService: `${ROOTS.APP}/gift-voucher/types/create/service`,
        edit: (type, id) => `${ROOTS.APP}/gift-voucher/types/edit/${type}/${id}`,
      },
      manage: `${ROOTS.APP}/gift-voucher/manage`,
      transactions: `${ROOTS.APP}/gift-voucher/transactions`,
      details: (id) => `${ROOTS.APP}/gift-voucher/details/${id}`,
      search: `${ROOTS.APP}/gift-voucher/search`,
    },
  },

  // DASHBOARD ADMIN
  admin: {
    root: ROOTS.ADMIN,
    dashboard: `${ROOTS.ADMIN}/dashboard`,
    companies: `${ROOTS.ADMIN}/companies`,
    companyDetails: `${ROOTS.ADMIN}/companies/:id`,
    invoices: `${ROOTS.ADMIN}/invoices`,
    users: `${ROOTS.ADMIN}/users`,
    giftVoucherTypes: `${ROOTS.ADMIN}/catalog`,
    giftVoucherList: `${ROOTS.ADMIN}/sales`,
  },

  // COMPANY
  company: {
    root: ROOTS.COMPANY,
    home: `${ROOTS.COMPANY}/:slug`, // Page d'accueil de l'établissement
    giftVoucherList: `${ROOTS.COMPANY}/:slug/gift-vouchers`, // Liste des bons cadeaux
    purchase: `${ROOTS.COMPANY}/:slug/buy/:voucherId`, // Page d'achat de bon cadeau
    paymentResult: `${ROOTS.COMPANY}/:slug/payment-result/:voucherId`,
    confirmation: `${ROOTS.COMPANY}/:slug/confirmation/:orderId`, // Page de confirmation d'un achat
    terms: `${ROOTS.COMPANY}/:slug/terms`, // Page de CGV
    contact: `${ROOTS.COMPANY}/:slug/contact`, // Page de contact
  },
};
