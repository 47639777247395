import axios from 'axios';
import {HOST_API} from 'src/ConfigGlobal';

/**
 * Création de l'instance Axios avec la configuration de base
 */
const axiosInstance = axios.create({
  baseURL: HOST_API,
  withCredentials: true, // Nécessaire pour envoyer/recevoir les cookies
});

/**
 * Configuration des endpoints de l'API
 */
export const endpoints = {
  auth: {
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    register: '/api/auth/register',
    verifyEmail: (token) => `/api/auth/verify-email/${token}`,
    user: '/api/auth/profile',
    refreshToken: '/api/auth/refresh-token',
    verifyToken: '/api/auth/verify-token',
    requestResetPassword: '/api/auth/request-reset-password',
    resetPassword: '/api/auth/reset-password',
    verifyResetToken: (token) => `/api/auth/verify-reset-token?token=${token}`,
  },
  app: {
    user: {
      create: (companyId) => `/api/app/${companyId}/user/create-user`,
      update: (companyId, userId) => `/api/app/${companyId}/user/update-user/${userId}`,
      updateAsAdmin: (companyId, userId) => `/api/app/${companyId}/user/update-user-admin/${userId}`,
      getUsersByAccount: (companyId, accountId) => `/api/app/${companyId}/user/${accountId}/all`,
      toggleGiftVoucherPurchaseNotifications: (companyId, userId) =>
        `/api/app/${companyId}/user/notifications/gift-vouchers/purchase/${userId}`,
      changePassword: (companyId) => `/api/app/${companyId}/user/change-password`,
      toggleActive: (companyId, userId) => `/api/app/${companyId}/user/toggle-active/${userId}`,
    },
    company: {
      update: (companyId) => `/api/app/${companyId}/company/update`,
      stripeConnect: (companyId) => `/api/app/${companyId}/stripe/connect/standard`,
    },
    giftVoucherType: {
      all: (companyId) => `/api/app/${companyId}/catalog/all`,
      create: (companyId) => `/api/app/${companyId}/catalog/create`,
      update: (companyId, typeId) => `/api/app/${companyId}/catalog/update/${typeId}`,
      delete: (companyId, typeId) => `/api/app/${companyId}/catalog/delete/${typeId}`,
      deleteMultiple: (companyId) => `/api/app/${companyId}/catalog/delete-multiple`,
      details: (companyId, typeId) => `/api/app/${companyId}/catalog/details/${typeId}`,
    },
    giftVoucher: {
      all: (companyId) => `/api/app/${companyId}/gift-voucher/all`,
      details: (companyId, giftVoucherId) => `/api/app/${companyId}/gift-voucher/details/${giftVoucherId}`,
      createManual: (companyId) => `/api/app/${companyId}/gift-voucher/create-manual`,
      update: (companyId, giftVoucherId) => `/api/app/${companyId}/gift-voucher/update/${giftVoucherId}`,
      search: (companyId, lastSixChars) => `/api/app/${companyId}/gift-voucher/search/${lastSixChars}`,
      templatePreview: (companyId, templateName) => `/api/app/${companyId}/gift-voucher/template-preview/${templateName}`,
      stats: {
        overall: (companyId) => `/api/app/${companyId}/gift-voucher/stats/overall`,
        periodic: (companyId) => `/api/app/${companyId}/gift-voucher/stats/current-period`,
        history: (companyId) => `/api/app/${companyId}/gift-voucher/stats/historical`,
        used: (companyId) => `/api/app/${companyId}/gift-voucher/stats/used-vouchers`,
        percentage: (companyId) => `/api/app/${companyId}/gift-voucher/stats/percentage-changes`,
        type: (companyId) => `/api/app/${companyId}/gift-voucher/stats/by-type`,
        status: (companyId) => `/api/app/${companyId}/gift-voucher/stats/status-summary`,
      },
    },
    transaction: {
      all: (companyId) => `/api/app/${companyId}/transaction/all`,
    },
    invoice: {
      all: (companyId) => `/api/app/${companyId}/invoice/all`,
      lastTen: (companyId) => `/api/app/${companyId}/invoice/last-ten`,
    },
  },
  public: {
    company: {
      details: '/api/public/company/details',
    },
    catalog: {
      all: '/api/public/catalog/all',
      details: '/api/public/catalog/details/:companyId/:giftVoucherTypeId',
    },
    giftVoucher: {
      createPaymentIntent: '/api/public/purchase/create-payment-intent',
      createOrder: '/api/public/purchase/create-gift-voucher',
      updateOrder: '/api/public/purchase/update-gift-voucher',
      transactionDetails: '/api/public/purchase/transaction-details/:transactionId',
    },
    commission: {
      calculate: '/api/public/commission/calculate/:companyId/:amount',
    },
    maintenance: {
      check: '/api/public/maintenance/check',
    },
  },
  admin: {
    accounts: '/api/admin/accounts',
    users: '/api/admin/users',
    companies: '/api/admin/companies',
    accountCreate: '/api/admin/account/create',
    adminCreate: '/api/admin/admin/create',
    giftVoucherTypes: {
      all: '/api/admin/gift-voucher-types/all',
    },
    giftVoucher: {
      all: '/api/admin/gift-voucher/all',
      details: '/api/admin/gift-voucher/details',
    },
    companies: {
      all: '/api/admin/companies/all',
      details: (id) => `/api/admin/companies/details/${id}`,
      update: (id) => `/api/admin/companies/update/${id}`,
      stats: '/api/admin/companies/stats',
      delete: (id) => `/api/admin/companies/delete/${id}`,
    },
    invoices: {
      all: '/api/admin/invoices/all',
      details: (id) => `/api/admin/invoices/details/${id}`,
      company: (companyId) => `/api/admin/invoices/company/${companyId}`,
      stats: '/api/admin/invoices/stats',
      monthly: (year, month) => `/api/admin/invoices/monthly/${year}/${month}`,
    },
  },
};

/**
 * Gestion du rafraîchissement des tokens
 */
let isRefreshing = false;
let failedQueue = [];

/**
 * Traite la file d'attente des requêtes échouées
 * @param {Error|null} error - Erreur éventuelle lors du rafraîchissement
 * @param {string|null} token - Nouveau token en cas de succès
 */
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**
 * Effectue la requête de rafraîchissement du token
 * @return {Promise} Résultat de la requête de rafraîchissement
 */
const refreshTokenRequest = async () => {
  try {
    const {data} = await axios.post(`${HOST_API}/api/auth/refresh-token`, {}, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    console.error('Erreur lors du rafraîchissement du token:', error);
    return Promise.reject(error);
  }
};

/**
 * Gère la déconnexion en cas d'erreur critique
 */
const handleCriticalError = () => {
  // Nettoyage du localStorage
  localStorage.removeItem('authState');

  // On ajoute un flag pour indiquer une déconnexion récente
  localStorage.setItem('recentlyLoggedOut', 'true');

  // Redirection vers la page de login
  const loginPath = '/auth/login';
  if (window.location.pathname !== loginPath) {
    window.location.href = loginPath;
  }
};

/**
 * Liste des codes d'erreur nécessitant une déconnexion
 */
const CRITICAL_ERROR_CODES = [
  'TOKEN_INVALID',
  'TOKEN_EXPIRED',
  'USER_NOT_FOUND',
  'TOKEN_NOT_FOUND',
  'TOKEN_MISSING',
];

/**
 * Intercepteur pour les requêtes
 */
axiosInstance.interceptors.request.use(
    (config) => {
    // Ici on pourrait ajouter des headers supplémentaires si nécessaire
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
);

/**
 * Intercepteur pour les réponses
 */
axiosInstance.interceptors.response.use(
    // Succès
    (response) => response,

    // Erreur
    async (error) => {
      const originalRequest = error.config;

      // Si l'erreur vient du rafraîchissement du token, on déconnecte
      if (originalRequest.url?.includes('/api/auth/refresh-token')) {
        handleCriticalError();
        return Promise.reject(error);
      }

      // Gestion des erreurs 401 (non authentifié)
      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
        // Si un rafraîchissement est déjà en cours, on met la requête en file d'attente
          try {
            await new Promise((resolve, reject) => {
              failedQueue.push({resolve, reject});
            });
            // Une fois le token rafraîchi, on réessaye la requête originale
            return axiosInstance(originalRequest);
          } catch (error) {
            return Promise.reject(error);
          }
        }

        // On marque la requête comme ayant déjà été réessayée
        originalRequest._retry = true;
        isRefreshing = true;

        try {
        // Tentative de rafraîchissement du token
          const data = await refreshTokenRequest();

          // Succès du rafraîchissement
          processQueue(null, data.accessToken);
          isRefreshing = false;

          // On réessaye la requête originale
          return axiosInstance(originalRequest);
        } catch (refreshError) {
        // Échec du rafraîchissement
          processQueue(refreshError, null);
          isRefreshing = false;
          handleCriticalError();
          return Promise.reject(refreshError);
        }
      }

      // Gestion des erreurs 403 (accès interdit)
      if (error.response?.status === 403) {
        const errorCode = error.response.data?.code;
        if (CRITICAL_ERROR_CODES.includes(errorCode)) {
          handleCriticalError();
        }
      }

      // Gestion des erreurs 429 (trop de requêtes)
      if (error.response?.status === 429) {
        const retryAfter = error.response.headers['retry-after'];
        if (retryAfter) {
        // On pourrait implémenter une logique de retry automatique ici
          console.warn(`Rate limit atteint. Réessayer dans ${retryAfter} secondes`);
        }
      }

      // Pour toute autre erreur, on la rejette simplement
      return Promise.reject(error);
    },
);

export default axiosInstance;
