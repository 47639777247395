import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useAuthContext} from 'src/hooks';
import {useRouter} from 'src/routes/hooks';

export default function RoleBasedGuard({roles, children}) {
  const {userRole} = useAuthContext();
  const router = useRouter();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!roles || !userRole) {
      setIsAuthorized(false);
      router.push('/403');
      return;
    }

    const hasRequiredRole = roles.includes(userRole);

    if (!hasRequiredRole) {
      router.push('/403');
      setIsAuthorized(false);
      return;
    }

    setIsAuthorized(true);
  }, [userRole, roles, router]);

  if (!isAuthorized) {
    return null; // Ne rien afficher tant que la vérification n'est pas faite
  }

  return <>{children}</>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
};
